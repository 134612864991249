import React from "react";
import worker from "../assets/hensem.jpg";
import worker5 from "../assets/hensem5-min.jpg";

const MainSection = () => {
  return (
    <>
     <div className="bg-gradient-to-r from-blue-500 to-indigo-700 px-10 flex justify-center items-center py-7">
        <div className="flex  justify-center items-center">
          <p className="text-lg md:text-2xl font-bold text-white text-center">
            "Swift peer-to-peer deliveries, your way, stress-free and secure"
          </p>
          <p className="text-4xl font-bold"></p>
          <p className="text-4xl font-bold"></p>
        </div>
      </div>
      <div className="md:w-full border">
        <img src={worker5} className="object-contain md:w-full md:h-full md:object-cover" alt="img"></img>
      </div>
     
    </>
  );
};

export default MainSection;

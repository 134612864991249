import React from "react";
import Contact from "./Contact";

const Footer = () => {
  return (
    <>
      <div className="bg-gradient-to-r from-blue-800 to-indigo-800 px-10 flex justify-center items-center py-7">
        <div className="flex flex-col justify-center items-center mt-10 w-full ">
          <div className="flex flex-col sm:flex-row justify-between items-center w-full  mb-10 ">

          <form action="https://formie.dev/form/817d1d3c-c125-4e2d-a74c-e80dd49e84f9" method="post" className="w-full md:w-1/2 md:px-20">
            <div className="flex flex-col gap-3 justify-end items-end text-md">
              <div className="w-full md:w-4/5 d-flex justify-start">
            <b className="text-2xl text-gray-200">Email us </b>
              </div>
              <input type="text" className="w-full py-2 px-3 sm:w-4/5 sm:py-2 sm:px-4  rounded-lg outline-1" name="email" placeholder="Email"></input>
              <textarea type="text" name="message" className="w-full py-2 px-3 sm:w-4/5 h-40  sm:py-2 sm:px-4  rounded-lg outline-1" placeholder="Message"></textarea>
              <div className="w-full md:w-4/5  flex justify-end">

              <button type="submit" className="w-full md:w-1/4 px-3 py-2 rounded-lg mt-2 bg-orange-500 hover:bg-orange-600 text-white"><b>Submit</b></button>
              </div>
            </div>
            
          </form>
          <div className="w-full mt-10 sm:mt-0 md:w-1/2 md:px-20">
              <Contact/>
          </div>
          </div>
         
        </div>
      </div>
      <div className="py-5 w-full flex justify-center bg-indigo-900">
            <p className="m-0 p-0 text-gray-300 font-semibold text-sm md:text-lg"> deliveria.my 2023 </p>
          </div>
    </>
  );
};

export default Footer;

import React from "react";
import logo from '../assets/deliveria logo.png'
import logo2 from '../assets/logo2.png'
import { useLocation } from "react-router-dom";

const navigation = [
    // { name: 'Product', href: '#' },
    { name: 'Home', href: '/' },
    // { name: 'Marketplace', href: '#' },
    // { name: 'Contact', href: '/contact' },
  ]
const Header = () => {

    const location = useLocation();

    console.log(location);
    return (
        <header className="inset-x-0 top-0 z-50 shadow-xl">
        <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                className="w-40"
                src={logo2}
                alt=""
              />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              
            >
              <span className="sr-only">Open main menu</span>
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <>
              {item.href == location.pathname ? (
                  <>
                  <a key={item.name} href={item.href} className="text-sm md:text-lg font-semibold leading-6 text-blue-800">
                    {item.name}
                  </a>
                  </>
                ) : (
                <a key={item.name} href={item.href} className="text-sm md:text-lg font-semibold leading-6 text-gray-900">
                {item.name}
              </a>
              )}
              </>
              
            ))}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end ">
            <a href="#" className="text-sm md:text-lg font-semibold leading-6 text-gray-900 bg-indigo-700 py-2 px-5 rounded-full text-white">
                Contact Us <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </nav>
        
      </header>
    )
}

export default Header
import React from 'react'
import { ArrowPathIcon, ChatBubbleLeftIcon, CloudArrowUpIcon, EnvelopeIcon, FingerPrintIcon, LockClosedIcon, ShoppingBagIcon, UserGroupIcon } from '@heroicons/react/24/outline'

const features = [
  {
    name: 'Peer to peer ',
    description:
      'Try our simple peer-to-peer delivery service. Connect with neighbors for quick, affordable shipping and secured. Easy, local, and time saving as just let us do the rest for the delivery job',
    icon: UserGroupIcon,
  },
  {
    name: 'Postage Runner ',
    description:
      'From doorstep to post station hassle-free! We pick up your parcel at your convenience and ensure a smooth journey to the nearest post station. Easy, simple, and efficient.',
    icon: EnvelopeIcon,
  },
   {
     name: 'Personal shopper', 
     description:
       'Discover stress-free shopping with our personal shopper service. We will navigate the aisles for you, ensuring you get exactly what you need without lifting a finger. Your time, your style let us make shopping simple.',
     icon: ShoppingBagIcon,
   },
   {
     name: 'Other services',
     description:
       'Others services include: Food delivery, Laundry, Courier, and more. Contact us for more information.',
     icon: ChatBubbleLeftIcon,
   },
]

export default function Feature() {
  return (
    <>
    <div className="mt-24 bg-blue-500 h-3 rounded-full m-auto w-1/6"></div>
    <div className="bg-white py-10 sm:py-20">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">Ship and deliver faster</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Everything you need for your delivery process
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Empower your shipments with simplicity, take a small step towards hassle-free delivery and seamless logistics. 
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
    </>
  )
}

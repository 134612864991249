import React from "react";

const stats = [
  { id: 1, name: "Total delivery done", value: "13 trips" },
  { id: 2, name: "Total delivery value", value: "RM 1000 ++" },
  { id: 3, name: "Total customers", value: "25" },
];

export default function Statistic() {
  return (
    <>
    <div className="mt-24 bg-blue-500 h-3 rounded-full m-auto w-1/6"></div>
      <div className="bg-white py-10 sm:py-20">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <dl className="flex flex-col md:flex-row">
            {stats.map((stat) => (
              <div
                key={stat.id}
                className="md:w-1/3 flex-col gap-y-4 px-5 py-3"
              >
                <div className="shadow-md py-10 text-center rounded-lg hover:shadow-lg">
                  <dt className="text-base leading-7 text-gray-500">
                    {stat.name}
                  </dt>
                  <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl ">
                    {stat.value}
                  </dd>
                </div>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </>
  );
}

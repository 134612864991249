import { useState } from 'react'
import Header from '../component/Header'
import MainSection from './MainSection'
import Benefit from './Benefit'
import Footer from './Footer'
import Feature from './Feature'
import Statistic from './Statistic'

export default function Home() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  
  return (
    <div className="">
       
        <MainSection/>
        {/* <Benefit/> */}
        <Statistic />
        <Feature />
        
      </div>
  )
}
